let TelegramUser = null;

if (window.Telegram && window.Telegram.WebApp) {
  const user = window.Telegram.WebApp.initDataUnsafe?.user;
  if (user) {
    TelegramUser = user;
  }
}

export default TelegramUser;
