import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../App.css";
import BackButton from "../../components/BackButton";
import NoResult from "../../assets/inbox_empty.png";
import defaultImage from "../../assets/default.png";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import LoadingAnim from "../../components/LoadingAnim";
import "swiper/css";
import "swiper/css/pagination";

const FilteredResults = () => {
  const location = useLocation();
  const { filteredResults } = location.state || { filteredResults: [] };
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    if (filteredResults.length > 0) {
      setIsLoading(false);
    }

    const propertiesWithImages = filteredResults.map((property) => {
      if (property.houseImage && property.houseImage.length > 0) {
        const imageUrls = property.houseImage.map((imageObj) => imageObj.image);
        property.houseImageSrc = imageUrls;
      }
      return property;
    });

    setProperties(propertiesWithImages);
  }, [filteredResults]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BackButton />
        <span className="font-bold text-xs" style={{ marginLeft: "8px" }}>
          GuestHome
        </span>
      </div>

      <div className="p-4">
        <h3 className="text-sm font-bold">Filtered Result</h3>

        {isLoading ? (
          <LoadingAnim />
        ) : filteredResults.length > 0 ? (
          filteredResults.map((property) => (
            <div
              key={property.id}
              className="property-item rounded-lg overflow-hidden bg-white mb-6"
            >
              <Link to={`/detail/${property.id}`} className="block">
                <div className="property-image w-full">
                  {property.houseImageSrc &&
                  property.houseImageSrc.length > 0 ? (
                    <Swiper
                      spaceBetween={4}
                      slidesPerView={1}
                      pagination={{ clickable: true }}
                      modules={[Pagination]}
                      className="property-swiper"
                    >
                      {property.houseImageSrc.map((src, index) => (
                        <SwiperSlide key={index}>
                          <LazyLoad height={350} offset={100}>
                            <img
                              src={src}
                              alt={`Property ${index + 1}`}
                              className="w-full"
                              style={{ borderRadius: "5px", height: "350px" }}
                            />
                          </LazyLoad>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <img
                      src={defaultImage}
                      alt={property.title}
                      className="w-full"
                      style={{ borderRadius: "5px", height: "350px" }}
                    />
                  )}
                </div>

                <div className="p-1">
                  <div className="flex justify-between items-center">
                    <h3 className="text-md font-bold truncate">
                      {property.title}
                    </h3>
                    <div className="flex items-center">
                      <i
                        className="fa fa-star"
                        style={{ color: "#FFA500" }}
                      ></i>
                      <span className="px-1 text-xs text-gray-500">
                        {property.postedBy?.rating}/5.0
                      </span>
                    </div>
                  </div>

                  <p className="text-xs text-gray-500">
                    {property.sub_description}
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    Posted by <b>@{property.postedBy?.userAccount?.username}</b>
                  </p>

                  <div className="flex justify-between items-center mt-1">
                    <p className="text-xs font-semibold">
                      {property.price} ETB / day
                    </p>
                    <p className="text-xs text-gray-400">
                      {property.distance} km away
                    </p>
                  </div>

                  <div className="flex justify-between mt-1">
                    <p className="text-xs text-gray-500 flex items-center">
                      <i className="fa fa-map-marker mr-1"></i>
                      {property.city} , {property.specificAddress}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center px-4 sm:px-0">
            <div className="flex flex-col justify-center items-center">
              <img
                src={NoResult}
                alt="no result found"
                className="mb-4 w-40 sm:w-22 md:w-30 lg:w-34 xl:w-32"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilteredResults;
