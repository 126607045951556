import React, { useEffect, useState } from "react";
import "./ApprovedBook.css";
import { API_BASE_URL, API_KEY } from "../../../config.js";
import { useParams } from "react-router-dom";
import ConfrimImg from "../../../assets/Icons/modal/confrim.svg";
import wifi from "../../../assets/Icons/facilities/wifi.png";
import tv from "../../../assets/Icons/facilities/tv.png";
import shower from "../../../assets/Icons/facilities/shower.png";
import workspace from "../../../assets/Icons/facilities/workspace.png";
import airCondition from "../../../assets/Icons/facilities/air_condition.png";
import balcony from "../../../assets/Icons/facilities/balcony.png";
import heating from "../../../assets/Icons/facilities/heating.png";
import kitchen from "../../../assets/Icons/facilities/kitchen.png";
import privateBathroom from "../../../assets/Icons/facilities/private_bathroom.png";
import laundry from "../../../assets/Icons/facilities/laundry.png";

import BackButton from "../../../components/BackButton.jsx";
import location from "../../../assets/Icons/location_icon.png";
import EmbeddedMap from "./EmbeddedMap/EmbeddedMap.jsx";
import TelegramUser from "../../../TelegramUser.js";
import { useOutletContext } from "react-router-dom";
import LoadingAnim from "../../../components/LoadingAnim.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const CancelModal = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const backToBooked = () => {
    onClose();
    navigate("/bookmark");
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full text-center mx-4">
        <img src={ConfrimImg} alt="confrim icon" />
        <h2 className="text-lg font-semibold text-black mb-4">
          {t("Cancel the booking")}...
        </h2>
        <p className="text-sm text-black">
          {t("Your Booking Has Been Canceled")}
        </p>
        <button
          className="mt-4 bg-blue-500 text-white rounded-lg text-sm py-3 px-20"
          style={{ backgroundColor: "#6938ef" }}
          onClick={backToBooked}
        >
          {t("Back to home")}
        </button>
      </div>
    </div>
  );
};

const Modal = ({ onClose, onConfirm, setShowCancelModal }) => {
  const { t } = useTranslation();

  const handleYesClick = () => {
    setShowCancelModal(true);
    onConfirm();
  };

  const handleNoClick = () => {
    onClose();
    setShowCancelModal(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end justify-center">
      <div className="bg-white w-full max-w-lg rounded-t-3xl p-6 flex flex-col justify-between animate-slide-up">
        {/* Modal Header */}
        <div className="text-center">
          <h2 className="text-lg font-bold text-black">
            {t("Book cancellation")}
          </h2>
          <p className="text-sm text-gray-600 mt-2">
            {t("Are you sure you want to cancel your booking")}? <br />
            {t("This action cannot be undone")}.
          </p>
        </div>

        {/* Modal Buttons */}
        <div className="flex justify-between mt-6">
          <button
            className="w-full mx-1 py-2 border border-black text-black rounded-xl"
            onClick={handleNoClick}
          >
            {t("NO")}
          </button>
          <button
            className="w-full mx-1 py-2 bg-black text-white rounded-xl"
            onClick={handleYesClick}
          >
            {t("YES")}
          </button>
        </div>
      </div>
    </div>
  );
};

// HouseDetail component
const ApprovedBook = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toggleFooter } = useOutletContext(); // Get the toggleFooter function
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  // const [imageSrcArray, setImageSrcArray] = useState([]);
  // const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    toggleFooter(!isModalOpen);

    const fetchPropertyDetail = async () => {
      const userData = {
        username: TelegramUser.username,
      };

      try {
        const response = await fetch(
          `${API_BASE_URL}/user/api/v1/reserved_house_Detail/?id=${id}&username=${userData.username}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": API_KEY,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            setShowCancelModal(true);
            setIsModalOpen(false);
            setProperty(null);
          } else {
            throw new Error("Failed to fetch property details");
          }
        } else {
          const data = await response.json();
          setProperty(data);

          if (data.houseImage && data.houseImage.length > 0) {
            const images = data.houseImage.map((img) => img.image);
            setImageSrc(images);
          }
        }
      } catch (error) {
        setError(error.message);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyDetail();
  }, [id, isModalOpen, toggleFooter]);

  const handleConfirmCancel = async (propertyId, username) => {
    const userData = {
      reservationId: propertyId,
      username: username,
    };

    // console.log(userData);

    try {
      const response = await fetch(
        `${API_BASE_URL}/user/api/v1/reservation/reject/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.ok) {
        setShowCancelModal(true);
        setIsModalOpen(false);
      } else {
        setShowCancelModal(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
        // window.Telegram.WebApp.showAlert(
        //   ":( Error occurred while canceling booking!"
        // );
      }
    } catch (error) {
      console.error("Error during cancellation:", error);
    }
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
    toggleFooter(false);
    setShowCancelModal(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    toggleFooter(true);
    setShowCancelModal(false);
  };

  if (loading) {
    return <LoadingAnim />;
  }

  if (showCancelModal) {
    return <CancelModal onClose={() => setShowCancelModal(false)} />;
  }

   if (error) {
     return (
       <p>
         {t("Error")}: {error}
       </p>
     );
   }

  if (!property) {
    return <p>{t("No property details available")}.</p>;
  }

  const items = property.house.sub_description
    ? property.house.sub_description.replace(/^,/, "").split(",")
    : [];

  const emojiMap = {
    "Wi-Fi": wifi,
    "Hot Shower": shower,
    "Private Bathroom": privateBathroom,
    "Workspace/Desk": workspace,
    "Air Conditioning": airCondition,
    "Kitchen Access": kitchen,
    "Balcony or Terrace": balcony,
    "Heating": heating,
    "TV": tv,
    "Laundry Facilities": laundry,
  };

  return (
    <>
      {showCancelModal && (
        <CancelModal onClose={() => setShowCancelModal(false)} />
      )}
      <BackButton />
      <div className="max-w-sm mx-auto bg-white text-white p-4 rounded-lg">
        <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
          {(property.house?.houseImage || []).map((img, index) => (
            <SwiperSlide key={index}>
              <img
                src={img.image}
                alt={`property-${index}`}
                className="w-full h-auto"
                style={{
                  maxHeight: "200px",
                  borderRadius: "10px",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <br />
        <div>
          <p className="text-lg font-bold text-black">{property.title}</p>
          <p className="text-sm text-gray-400">{property.description}</p>

          <p className="text-sm text-black">
            <b>{t("posted by")} </b>{" "}
            {property.house.postedBy?.userAccount?.first_name}{" "}
            {property.house.postedBy?.userAccount?.last_name}
          </p>
        </div>
        <br />
        <hr />
        <div className="text-sm text-black py-2">
          <p className="flex items-center">
            <b>{t("About Reservations")}</b>
          </p>
          <p className="text-sm text-black py-1">
            {t("Reservation ID")}: {property.id}
          </p>
          <p className="text-sm text-black py-1">
            {t("Requested Reservation Price")}: {property.house.price}
          </p>
          <p className="text-sm text-black py-1">
            {t("Unit of Price")}: {property.house.unit}
          </p>
          <p className="text-sm text-black py-1">
            {t("Reservation Check in")} : {property.checkIn}
          </p>
          <p className="text-sm text-black py-1">
            {t("Reservation Check out")} : {property.checkOut}
          </p>
          <p className="text-sm text-black py-1">
            {t("Reservation Decision time")} :{" "}
            {property.decisionTime || "Pending"}
          </p>
          <p className="text-sm text-black py-1">
            {t("Reservation Decision")} : <b>{property.status}</b>
          </p>
        </div>
        <br />
        <hr />
        <div className="text-sm text-black py-2">
          <p className="flex items-center">
            <b>{t("About Host")}</b>
          </p>
          <p className="text-sm text-black py-1">
            {t("Phone number")}: {property.house.postedBy?.phoneNumber}
            <button
              className="ml-2 px-1 py-1 text-white text-xs bg-green-500 rounded-lg"
              onClick={() =>
                window.open(`tel:${property.house.postedBy?.phoneNumber}`)
              }
            >
              {t("Call")}
            </button>
            <button
              className="ml-2 px-1 py-1 text-white text-xs bg-blue-500 rounded-lg"
              onClick={() =>
                window.open(`sms:${property.house.postedBy?.phoneNumber}`)
              }
            >
              {t("Message")}
            </button>
          </p>
          <p className="text-sm text-black py-1 px-1">
            {t("Telegram Username")}:
            <a
              href={`http://t.me/${property.house.postedBy?.userAccount?.username}`}
            >
              {property.house.postedBy?.userAccount?.username}
            </a>
          </p>
        </div>
        {/* Map through items and display the emoji and description */}
        <div
          className="flex flex-col mt-2"
          style={{
            backgroundColor: "#f2f4f7",
            borderRadius: "12px",
            padding: "16px",
          }}
        >
          <p
            className="text-black font-bold"
            style={{ fontSize: "12px" }}
          >
            {t("Facilities")}
          </p>

          <div className="flex flex-wrap space-x-2 mt-2">
            {items.map((item, index) => {
              const trimmedItem = item.trim();
              const emojiOrIcon = emojiMap[trimmedItem];

              return (
                <div key={index} className="flex items-center text-black p-1">
                  {emojiOrIcon ? (
                    <div className="flex flex-col items-center">
                    <img
                      src={emojiOrIcon}
                      alt={trimmedItem}
                      className="w-9 h-9"
                    />
                    <span className="text-xs mt-1">{trimmedItem}</span>
                    </div>
                  ) : (
                    <span>{trimmedItem}</span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="text-xs text-black py-4">
          <p className="flex items-center">
            <img
              src={location}
              alt="location icon"
              width={25}
              height={25}
              className="mr-2"
            />
            {property.house.city},{property.house.specificAddress}
          </p>
        </div>

        <EmbeddedMap
          latitude={property.house.latitude}
          longitude={property.house.longitude}
          title={property.house.title}
        />
        <br />
        <hr></hr>
        <p className="text-black text-sm py-2">{t("Book cancellation")}</p>
        <p
          className="text-gray-500 py-1"
          style={{ fontSize: "12px" }}
        >
          {t(
            "Any cancellation policy details (e.g., “No refund for cancellations made less than 24 hours before check-in”)"
          )}
        </p>
        <div className="flex items-center justify-between p-2 w-full">
          <div className="w-full">
            <button
              className="text-black text-xs py-2 px-4 rounded-xl w-full"
              style={{ backgroundColor: "#ffff", border: "solid black" }}
              onClick={openModal}
            >
              {t("Cancel the booking")}
            </button>
          </div>
        </div>
        {/* Render the modal if it's open */}
        {isModalOpen && (
          <Modal
            onClose={closeModal}
            setShowCancelModal={setShowCancelModal}
            onConfirm={() =>
              handleConfirmCancel(
                property.id,
                property.user?.userAccount?.username
              )
            }
          />
        )}
      </div>
    </>
  );
};

export default ApprovedBook;
