import React from "react";
import "./DualRangeSlider.css";

const DualRangeSlider = ({ minValue, maxValue, setMinValue, setMaxValue }) => {
 const handleMinChange = (e) => {
   setMinValue(Number(e.target.value));
 };

  const handleMaxChange = (e) => {
    // Cap the value to 3000
    const value = Math.min(Number(e.target.value), 3000);
    setMaxValue(value);
  };

  return (
    <div className="slider-container">
      <input
        type="range"
        min="0"
        max="3000"
        value={minValue}
        onChange={handleMinChange}
        className="thumb thumb--left"
        style={{ zIndex: minValue > 75 ? 5 : 1 }}
      />
      <input
        type="range"
        min="0"
        max="3000"
        value={maxValue}
        onChange={handleMaxChange}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div
          className="slider__range"
          style={{
            left: `${(minValue / 3000) * 100}%`,
            right: `${100 - (maxValue / 3000) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};

export default DualRangeSlider;
