import React from "react";
import "./MenuList.css";

const MenuList = ({ image, title }) => {
  return (
    <div
      className="property-card"
      style={{
        backgroundColor: "#f2f4f7",
        border: "none",
        height: "80px",
      }}
    >
      <div className="image-container">
        <img
          src={image}
          alt="Property"
          className="property-image"
          style={{
            width: "32px",
            height: "32px",
          }}
        />
      </div>
      <div className="property-info">
        <h3 className="property-title">{title}</h3>
      </div>
    </div>
  );
};

export default MenuList;
