import React from "react";
import BackArrow from "../assets/Icons/back_arrow.png";

function BackButton() {
  const handleBackClick = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.close();
    }
  };

  return (
    <div>
      <button
        className="text-black text-bold py-3 mb-2"
        style={{ paddingTop: "15px", marginTop: "10px" }}
        onClick={handleBackClick}
      >
        <img src={BackArrow} alt="back arrow" width={50} height={50}/>
      </button>
    </div>
  );
};

export default BackButton;
