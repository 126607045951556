import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import DualRangeSlider from "./DualRangeSlider.jsx";
import "./SearchHouse.css";
import { API_BASE_URL, API_KEY } from "../config.js";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
// import FilteredResults from "../pages/FilterResult/FilteredResults.jsx";
import HotelandResort from "../assets/icon_png/hotel&resort.svg";
import Houses from "../assets/icon_png/houses.svg";
import Pension from "../assets/icon_png/pension.svg";
import Apartment from "../assets/icon_png/apartment.svg";
import PrivateRoom from "../assets/icon_png/privateroom.svg";
import Warehouses from "../assets/icon_png/warehouse.svg";
import SharedSpace from "../assets/icon_png/shared-space.svg";
import Hall from "../assets/icon_png/hall.svg";
import GuestHouse from "../assets/icon_png/guest-house.svg";
import HotelBedroom from "../assets/icon_png/hotel-bedroom.svg";
import Other from "../assets/icon_png/other.svg";
import { useTranslation } from "react-i18next";

const SearchHouse = ({ onFilter, onApplyFilters }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isNearbySearch, setIsNearbySearch] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [selectedCity, setSelectedCity] = useState("");
  const [category, setCategory] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState({
    min: 100,
    max: 3000,
  });
  const [showModal, setShowModal] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [selectedHouseType, setSelectedHouseType] = useState("Private Room");
  const [showFilteredResults, setShowFilteredResults] = useState(false);

  console.log(location, selectedCity, selectedPriceRange, totalCount, category);

  const handleNearbySearchToggle = () => {
    setIsNearbySearch((prev) => !prev);

    // Use Geolocation API to fetch the user's current location
    if (!isNearbySearch && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      setLocation({ latitude: null, longitude: null });
    }
  };

  const fetchFilteredData = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/api/v1/filter/`,
        {
          city: selectedCity || null,
          minPrice: selectedPriceRange?.min || null,
          maxPrice: selectedPriceRange?.max || null,
          latitude: isNearbySearch ? location.latitude : null,
          longitude: isNearbySearch ? location.longitude : null,
          category: category || null,
          range: 10,
        },
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      // console.log(response.data.results);

      setFilteredResults(response.data.results);
      setTotalCount(response.data.count);
      setNotificationCount(response.data.count);
    } catch (error) {
      console.error("Error fetching filtered results:", error);
    }
  };

  // Define a debounced version of fetchFilteredData
  const debouncedFetchFilteredData = useCallback(
    _.debounce(fetchFilteredData, 500), // 500ms debounce time
    [selectedCity, selectedPriceRange, location, category]
  );

  useEffect(() => {
    debouncedFetchFilteredData();
  }, [
    selectedCity,
    selectedPriceRange,
    location,
    category,
    debouncedFetchFilteredData,
  ]);

  const handleCategoryClick = (selectedCategory, label) => {
    setCategory(selectedCategory);
    setSelectedHouseType(label);
    fetchFilteredData();
  };

  const handleMinPriceChange = (min) => {
    setSelectedPriceRange((prev) => ({ ...prev, min }));
    fetchFilteredData();
  };

  const handleMaxPriceChange = (max) => {
    setSelectedPriceRange((prev) => ({ ...prev, max: Math.min(max, 3000) }));
    fetchFilteredData();
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const navigate = useNavigate();

  const handleApplyFilters = async () => {
    await fetchFilteredData();
    setShowModal(false);
    navigate("/filtered-results", { state: { filteredResults } });
  };

  const handleSearchChange = (e) => {
    const newTerm = e.target.value;
    setSearchTerm(newTerm);

    // Call the onFilter callback function whenever the search term changes
    if (onFilter) {
      onFilter(newTerm);
    }
  };

  const handleFilterClick = () => {
    setShowModal(true);
  };

  // Function to reset all filters to default values
  const resetFilters = () => {
    setSearchTerm("");
    setIsNearbySearch(false);
    setLocation({ latitude: null, longitude: null });
    setSelectedCity("");
    setCategory("");
    setSelectedPriceRange({ min: 100, max: 3000 });
    setSelectedHouseType("Private Room");
  };

  const iconData = [
    { label: "Private Room", icon: PrivateRoom, category: "private Room" },
    {
      label: "Hotel and Resort",
      icon: HotelandResort,
      category: "Hotel and Resort",
    },
    { label: "Houses", icon: Houses, category: "Houses" },
    { label: "Pension", icon: Pension, category: "pension" },
    { label: "Apartment", icon: Apartment, category: "Apartment" },
    { label: "Warehouses", icon: Warehouses, category: "warehouse" },
    { label: "Shared spaces", icon: SharedSpace, category: "shared space" },
    { label: "Hall", icon: Hall, category: "Hall" },
    { label: "Guest House", icon: GuestHouse, category: "guest house" },
    { label: "Hotel Bedroom", icon: HotelBedroom, category: "hotel bedroom" },
    { label: "Other", icon: Other, category: "Other" },
  ];

  return (
    <>
      <div className="search-container flex justify-center w-full px-4 md:px-8 lg:px-12">
        <div className="search-wrapper flex items-center gap-2 md:gap-4 w-full max-w-md">
          <div className="relative flex-grow">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search any type of house"
              className="search-input w-full py-8 h-24 px-4 rounded-lg border-none outline-none text-sm bg-gray-200 dark:bg-gray-700"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div
            className="relative filter-icon cursor-pointer"
            onClick={handleFilterClick}
          >
            <FilterListOutlinedIcon className="w-6 h-6 text-black-600 dark:text-black-300" />
            {notificationCount > 0 && (
              <span className="absolute top-0 right-0 w-5 h-5 rounded-full bg-red-500 text-white text-xs flex items-center justify-center">
                {notificationCount}
              </span>
            )}
          </div>
        </div>

        <Modal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          onApplyFilters={handleApplyFilters}
          totalCount={totalCount}
          selectedPriceRange={selectedPriceRange}
          setSelectedPriceRange={setSelectedPriceRange}
          handleMinPriceChange={handleMinPriceChange}
          handleMaxPriceChange={handleMaxPriceChange}
          selectedCity={selectedCity}
          handleCityChange={handleCityChange}
          isNearbySearch={isNearbySearch}
          iconData={iconData}
          onNearbySearchToggle={handleNearbySearchToggle}
          handleCategoryClick={handleCategoryClick}
          resetFilters={resetFilters}
          selectedHouseType={selectedHouseType}
        />
      </div>
    </>
  );
};

// Modal component
const Modal = ({
  showModal,
  onClose,
  onApplyFilters,
  totalCount,
  selectedPriceRange,
  setSelectedPriceRange,
  selectedCity,
  handleCityChange,
  isNearbySearch,
  iconData,
  onNearbySearchToggle,
  handleCategoryClick,
  resetFilters,
  selectedHouseType,
}) => {
  const { t } = useTranslation();

  if (!selectedPriceRange) return null;

  const handleMinPriceChange = (input) => {
    const value =
      typeof input === "object" ? Number(input.target.value) : input;
    setSelectedPriceRange((prev) => ({
      ...prev,
      min: Math.min(value, selectedPriceRange.max - 100),
    }));
  };

  const handleMaxPriceChange = (input) => {
    const value =
      typeof input === "object" ? Number(input.target.value) : input;

    // Cap the slider to 3000, but allow the backend to show results above that
    const cappedValue = Math.min(value, 3000);
    setSelectedPriceRange((prev) => ({
      ...prev,
      max: cappedValue,
      maxDisplay: cappedValue === 3000 ? "3000+" : cappedValue,
    }));
  };

  return (
    <>
      {showModal && (
        <div
          className="modal-backdrop fixed inset-0 bg-black opacity-50 z-40"
          onClick={onClose}
        ></div>
      )}

      <div
        className={`modal fixed inset-0 flex items-end justify-center z-50 transform transition-transform duration-10 ${
          showModal ? "translate-y-0" : "translate-y-full"
        }`}
      >
        <div className="modal-content bg-white p-6 rounded-t-lg shadow-lg w-full h-3/4 md:w-full md:h-1/2 relative">
          <h2 className="text-lg font-semibold mb-4">{t("Filter Houses")}</h2>

          <label className="text">
            {t("Selected house type")}:
            <b style={{ color: "#6938ef" }}>{selectedHouseType}</b>
          </label>

          <div className="flex overflow-x-auto space-x-2 p-4">
            {iconData.map((item, index) => (
              <div
                key={index}
                className={`flex flex-col items-center cursor-pointer p-2`}
                onClick={() => handleCategoryClick(item.category, item.label)}
              >
                <img
                  src={item.icon}
                  alt={t(item.label)}
                  className="w-10 h-10 hover:bg-[#6938ef] rounded-full transition-all duration-300"
                />

                <span
                  className="w-full mt-2 whitespace-nowrap"
                  style={{ fontSize: "12px" }}
                >
                  {item.label}
                </span>
              </div>
            ))}
          </div>

          <div className="flex flex-col space-y-4">
            <div>
              <label className="text">{t("Price range")}</label>
              <br />
              <br />
              <div className="flex flex-col space-y-2">
                <div className="relative max-w-xl w-full">
                  <DualRangeSlider
                    minValue={selectedPriceRange.min}
                    maxValue={Math.min(selectedPriceRange.max, 3000)}
                    setMinValue={handleMinPriceChange}
                    setMaxValue={handleMaxPriceChange}
                  />

                  <div className="flex items-center justify-between pt-5 space-x-4 text-sm text-gray-700">
                    <div className="text-center">
                      <input
                        type="text"
                        maxLength="5"
                        disabled
                        value={selectedPriceRange.min}
                        onChange={handleMinPriceChange}
                        className="w-24 px-3 py-1 rounded-full text-center border border-gray-300 bg-gray-50 focus:border-purple-500 focus:outline-none"
                      />
                      <p className="text-xs text-gray-500">{t("Minimum")}</p>
                    </div>

                    <div className="text-center">
                      <input
                        type="text"
                        maxLength="5"
                        disabled
                        value={
                          selectedPriceRange.max > 2999
                            ? "3000+"
                            : selectedPriceRange.max
                        }
                        onChange={handleMaxPriceChange}
                        className="w-24 px-3 py-1 rounded-full text-center border border-gray-300 bg-gray-50 focus:border-purple-500 focus:outline-none"
                      />
                      <p className="text-xs text-gray-500">{t("Maximum")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div>
              <label className="text mb-4">{t("Location")}</label>
              <select
                className="border border-gray-300 p-3 rounded-md w-full"
                value={selectedCity}
                onChange={handleCityChange}
              >
                <option value="Addis Ababa">{t("Addis Ababa")}</option>
                <option value="Adama">{t("Adama")}</option>
                <option value="Bahir Dar">{t("Bahir dar")}</option>
                <option value="Hawassa">{t("Hawassa")}</option>
                <option value="Gonder">{t("Gonder")}</option>
                <option value="Mekele">{t('Mekele')}</option>
                <option value="Arbaminch">{t("Arbaminch")}</option>
                <option value="Hosaena">{t("Hosaena")}</option>
                <option value="Dese">{t("Dese")}</option>
                <option value="Aksum">{t("Aksum")}</option>
                <option value="Dire Dawa">{t("Dire Dawa")}</option>
                <option value="Jijiga">{t("Jijiga")}</option>
                <option value="Harar">{t("Harar")}</option>
                <option value="Samara">{t("Samara")}</option>
              </select>
            </div>

            <div className="flex items-center align-middle">
              <input
                type="checkbox"
                className="w-4 h-4"
                style={{ marginTop: "17px" }}
                checked={isNearbySearch}
                onChange={onNearbySearchToggle}
              />
              <h2 className="text-sm ml-2">{t("Nearby Search")}</h2>
            </div>
            <br />

            <div className="flex w-full">
              <button
                className="bg-white-500 border border-black text-black rounded-md text-sm px-12 py-3 w-auto"
                onClick={resetFilters}
              >
                {t("Clear all")}
              </button>
              <div className="flex-grow"></div>
              <button
                className="bg-blue-500 text-white rounded-md text-sm px-6 py-4"
                style={{ backgroundColor: "#6938ef" }}
                onClick={onApplyFilters}
              >
                {t("Show")} ({totalCount}) {t("results")}
              </button>
            </div>
          </div>

          <button
            onClick={onClose}
            className="absolute top-2 right-2 font-bold text-red-500 hover:text-gray-700"
          >
            {("Close")} &#x2715;
          </button>
        </div>
      </div>
    </>
  );
};

export default SearchHouse;
