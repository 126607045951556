import React from 'react'

function LoadingAnim() {
  return (
    <div className="flex justify-center items-center my-6">
      <div className="relative flex justify-center items-center">
        {/* Spinner animation */}
        <div
          className="animate-spin rounded-full border-4 border-purple-600 border-t-transparent w-8 h-8"
          role="status"
        ></div>
        <span className="absolute top-12 text-gray-700 text-xs ml-2">
          Loading...
        </span>
      </div>
    </div>
  );
}

export default LoadingAnim;