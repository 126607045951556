import React, { useEffect, useState } from "react";
import "./HouseDetail.css";
import { API_BASE_URL, API_KEY } from "../../config.js";
import { useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import defaultImage from "../../assets/default.png";
import ConfrimImg from "../../assets/Icons/modal/confrim.svg";

import wifi from "../../assets/Icons/facilities/wifi.png";
import tv from "../../assets/Icons/facilities/tv.png";
import shower from "../../assets/Icons/facilities/shower.png";
import workspace from "../../assets/Icons/facilities/workspace.png";
import airCondition from "../../assets/Icons/facilities/air_condition.png";
import balcony from "../../assets/Icons/facilities/balcony.png";
import heating from "../../assets/Icons/facilities/heating.png";
import kitchen from "../../assets/Icons/facilities/kitchen.png";
import laundry from "../../assets/Icons/facilities/laundry.png";
import privateBathroom from "../../assets/Icons/facilities/private_bathroom.png";

import location from "../../assets/Icons/location_icon.png";
import TelegramUser from "../../TelegramUser.js";
import BackButton from "../../components/BackButton.jsx";
import LoadingAnim from "../../components/LoadingAnim.jsx";
import LazyLoad from "react-lazyload";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

// PendingModal component
const PendingModal = ({ onClose }) => {
  const { t } = useTranslation();

  return (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-50">
    <div className="bg-white p-6 rounded-lg max-w-md w-full text-center mx-4">
      <img src={ConfrimImg} alt="confrim icon" />
      <h2 className="text-lg font-semibold text-black mb-4">{t("Book Pending")}...</h2>
      <p className="text-sm text-black">
        {t("You will get a call in a minute from the host or check booked menu for more information")}
      </p>
      <button
        onClick={onClose}
        className="mt-4 bg-blue-500 text-white rounded-lg text-sm py-3 px-20"
        style={{ backgroundColor: "#6938ef" }}
      >
        {t("Done")}
      </button>
    </div>
  </div>
 );
};

// Modal component
const Modal = ({
  showModal,
  onClose,
  userInfo,
  houseId,
  setShowPendingModal,
}) => {
  // Controls PendingModal visibility
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkIn, setCheckInDate] = useState("");
  const [checkOut, setCheckOutDate] = useState("");
  const [type_proof_of_identity, setIdType] = useState("Kebele ID");

  const { t } = useTranslation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setShowPendingModal(true);

    const formData = {
      house: houseId,
      username: userInfo?.username,
      checkIn,
      checkOut,
      phoneNumber,
      type_proof_of_identity,
      chatId: userInfo?.id,
      first_name: firstName,
      last_name: lastName,
    };

    console.log(formData);

    try {
      const response = await fetch(
        `${API_BASE_URL}/user/api/v1/reservation/save/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log(response);
        // const data = await response.json();
        setShowPendingModal(true);
        onClose();
        // window.Telegram.WebApp.showAlert("Room Reservation successfully sent!");
      } else {
        setShowPendingModal(false);
        console.log(response);
        window.Telegram.WebApp.showAlert(
          "Failed: Room reserved by another guest."
        );
        console.log(response);
      }
    } catch (error) {
      setShowPendingModal(false);
      window.Telegram.WebApp.showAlert("An error occurred. Please try again.");
    }
  };

  // If showModal is false, don't render anything
  if (!showModal) return null;

  return (
    <div>
      <div
        className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center"
        style={{ zIndex: "10" }}
      >
        <div
          className="bg-white p-6 rounded-lg max-w-md w-full h-full"
          style={{
            maxHeight: "100vh",
            overflowY: "auto", // Enable vertical scrolling
            overflowX: "auto",
          }}
        >
          <h4 className="text-md font-semibold text-black mb-4">
            {t("Booking Detail")}
          </h4>
          <p className="text-xs text-black">
            {t("Fill out the information below and confirm your booking")}.
          </p>
          <br />
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2 text-black">
                {t("First Name")} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
                className="w-full px-3 py-2 border rounded-lg bg-gray-100 text-black"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2 text-black">
                {t("Last Name")} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
                className="w-full px-3 py-2 border rounded-lg bg-gray-100 text-black"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-bold mb-2 text-black">
                {t("Phone number")} <span className="text-red-500">*</span>
              </label>
              <div className="flex space-x-2">
                <input
                  type="text"
                  className="w-16 px-3 py-2 border rounded-lg bg-gray-100 text-black"
                  placeholder="+251"
                  disabled
                />
                <input
                  type="number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Contact number"
                  className="flex-1 px-3 py-6 border rounded-lg bg-gray-100 text-black text-sm"
                  required
                />
              </div>
            </div>

            <div className="flex space-x-2">
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2 text-black">
                  {t("Check-in")} <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  value={checkIn}
                  onChange={(e) => setCheckInDate(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg bg-gray-100 text-black"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-bold mb-2 text-black">
                  {t("Check-out")} <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  value={checkOut}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg bg-gray-100 text-black"
                  required
                />
              </div>
            </div>

            <div className="flex space-x-2">
              <div className="mb-4 w-full">
                <label className="block text-sm font-bold mb-2 text-black">
                  {t("Select ID type you have")}
                  <span className="text-red-500">*</span>
                </label>
                <div className="radio-group custom-grid">
                  {[
                    "National ID",
                    "Kebele ID",
                    "Driving License",
                    "Passport",
                  ].map((type) => (
                    <div
                      key={type}
                      className={`radio-option ${
                        type_proof_of_identity === type ? "active" : ""
                      }`}
                      onClick={() => setIdType(type)}
                    >
                      <input
                        type="radio"
                        id={type}
                        name="type_proof_of_identity"
                        value={type}
                        checked={type_proof_of_identity === type}
                        onChange={() => setIdType(type)}
                        className="hidden"
                      />
                      <label htmlFor={type}>{type}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <br />
            <hr />
            <br />
            <div className="flex space-x-8">
              <button
                onClick={onClose}
                className="bg-white-500 border border-black text-black rounded-md text-sm px-10 py-3"
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white rounded-lg text-sm px-5 py-3"
                style={{ backgroundColor: "#6938ef" }}
              >
                {t("Confirm Booking")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// HouseDetail component
const HouseDetail = () => {
  const { t } = useTranslation();

  const [showPendingModal, setShowPendingModal] = useState(false);
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toggleFooter } = useOutletContext();

  useEffect(
    () => {
      toggleFooter(!isModalOpen);

      const fetchPropertyDetail = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}/user/api/v1/house_detail/?id=${id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "x-api-key": API_KEY,
              },
            }
          );

          const data = await response.json();
          //console.log(data);

          if (data.houseImage && data.houseImage.length > 0) {
            const imageUrls = data.houseImage.map((imageObj) => imageObj.image);
            data.houseImageSrc = imageUrls;
          }

          setProperty(data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchPropertyDetail();
      setUserInfo(TelegramUser);
    },
    [id],
    [isModalOpen, toggleFooter]
  );

  const handleBookClick = () => {
    setShowModal(true);
    setShowPendingModal(false);
    setIsModalOpen(true);
    toggleFooter(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsModalOpen(false);
    toggleFooter(true);
  };

  if (loading) {
    return <LoadingAnim />;
  }

  if (error) {
    return <p>{t("Error")}: {error}</p>;
  }

  if (!property) {
    return <p>{t("No property details available")}.</p>;
  }

  const items = property?.sub_description.split(",");
  const emojiMap = {
    "Wi-Fi": wifi,
    "Hot Shower": shower,
    "Private Bathroom": privateBathroom,
    "Workspace/Desk": workspace,
    "Air Conditioning": airCondition,
    "Kitchen Access": kitchen,
    "Balcony or Terrace": balcony,
    "Heating": heating,
    "TV": tv,
    "Laundry Facilities": laundry,
  };

  return (
    <>
      {showPendingModal && (
        <PendingModal onClose={() => setShowPendingModal(false)} />
      )}
      <div className="flex items-center p-0 mb-0">
        <BackButton />
        <span className="font-bold text-xs ml-2">GuestHome</span>
      </div>

      <div className="max-w-lg mx-auto bg-white text-white p-4 rounded-lg">
        <div className="flex flex-col items-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          {property.houseImageSrc && property.houseImageSrc.length > 0 ? (
            <Swiper
              spaceBetween={4}
              slidesPerView={1}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              className="property-swiper max-w-full sm:max-w-xs md:max-w-sm lg:max-w-md"
            >
              {property.houseImageSrc.map((src, index) => (
                <SwiperSlide key={index}>
                  <LazyLoad height={350} offset={100}>
                    <img
                      src={src}
                      alt="house-icon"
                      className="w-full h-auto"
                      style={{
                        maxHeight: "260px",
                        maxWidth: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  </LazyLoad>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <img
              src={defaultImage}
              alt={property.title}
              className="w-full"
              style={{ borderRadius: "5px", height: "350px" }}
            />
          )}
        </div>
        <br />
        <div>
          <p className="text-lg font-bold text-black sm:text-xl md:text-2xl">
            {property?.title}
          </p>
          <p className="text-sm text-gray-400 sm:text-base md:text-lg">
            {property?.description}
          </p>
          <p className="text-sm text-black sm:text-base md:text-lg">
            <b>{t("posted by")} </b>{" "}
            {property?.postedBy?.userAccount?.first_name}
          </p>
        </div>
        <br />
        <div className="text-sm text-black">
          <p className="flex items-center">
            <img
              src={location}
              alt="location icon"
              width={25}
              height={25}
              className="mr-2"
            />
            {property?.city},{property?.specificAddress}
          </p>
        </div>
        <br />
        {/* Map through items and display the emoji and description */}
        <div
          className="flex flex-col mt-2"
          style={{
            backgroundColor: "#f2f4f7",
            borderRadius: "12px",
            padding: "16px",
          }}
        >
          <p
            className="text-black font-bold sm:text-base md:text-lg lg:text-xl"
          >
            {t("Facilities")}
          </p>

          <div className="flex flex-wrap space-x-2 mt-2">
            {items.map((item, index) => {
              const trimmedItem = item.trim();
              const emojiOrIcon = emojiMap[trimmedItem];

              return (
                <div
                  key={index}
                  className="flex flex-col items-center text-black p-1"
                >
                  {emojiOrIcon ? (
                    <div className="flex flex-col items-center">
                      <img
                        src={emojiOrIcon}
                        alt={trimmedItem}
                        className="w-9 h-9"
                      />
                      <span className="text-xs mt-1">{trimmedItem}</span>
                    </div>
                  ) : (
                    <span>{trimmedItem}</span>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <br />
        <hr></hr>
        <br />
        <div className="flex items-center justify-between p-2 sm:p-3 md:p-4">
          <div>
            <p className="text-black font-bold text-sm sm:text-base md:text-lg">
              {property?.price} {t("ETB")}
            </p>
            <p className="text-sm text-gray-400">{t("per day")}</p>
          </div>

          <div>
            <button
              onClick={handleBookClick}
              className="text-white py-3 px-20 rounded-xl"
              style={{ backgroundColor: "#6938ef" }}
            >
              {t("Book")}
            </button>
          </div>
        </div>
        <Modal
          showModal={showModal}
          onClose={handleCloseModal}
          userInfo={userInfo}
          houseId={id}
          setShowPendingModal={setShowPendingModal}
        />
      </div>
    </>
  );
};

export default HouseDetail;
