import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../pages/Home/Footer/Footer";

const Layout = () => {
  const [isFooterVisible, setIsFooterVisible] = useState(true); 

  const toggleFooter = (isVisible) => {
    setIsFooterVisible(isVisible);
  };

  return (
    <div className="layout">
      <div className="content">
        {/* Render the matching route's component and pass the toggleFooter function */}
        <Outlet context={{ toggleFooter }} />
      </div>
      {isFooterVisible && <Footer />}
    </div>
  );
};

export default Layout;
