import React from "react";
import BackButton from "../../../../components/BackButton";
import "./Policy.css";

function Policy() {
  // Custom back function

  return (
    <div className="policy-container">
      <div style={{ display: "flex", alignItems: "center" }}>
        <BackButton />
        <span className="font-bold text-xs" style={{ marginLeft: "8px" }}>
          GuestHome
        </span>
      </div>

      <h1>Terms and Conditions</h1>
      <p>
        <strong>Date:</strong> October 23 at 10:39 AM
      </p>

      <section>
        <h2>Article 1: Introduction and Definitions</h2>
        <h2>Introduction</h2>
        <ol type="1">
          <li>
            The GuestHome Platform provides a digital marketplace where property
            owners (Hosts) can list their properties and connect with potential
            renters (Guests). These Terms and Conditions outline the rights and
            responsibilities of both parties and the operational framework of
            the Platform. By Using the Platform, both Hosts and Guests agree to
            abide by these terms.
          </li>
        </ol>
      </section>
      <section>
        <h2>Definitions</h2>
        <ol>
          <li>
            <b>1. Host:</b> A property owner or manager who lists their property
            on the GuestHome Platform for booking purposes. The Host is
            responsible for providing accurate information about the property
            such as Hotel and Resort , Pension, Warehouse, House, Private room,
            Shared space, Unit of Condominium, Apartment, Hotel room, Guest
            house ,Hall and others ensuring it is clean and safe for Guests, and
            complying with all relevant laws and Regulations.
          </li>
          <li>
            <b>2. Guest:</b> An individual who books a property through the
            GuestHome Platform for Accommodation. The Guest agrees to the terms
            and conditions set forth by the Host and is Responsible for
            maintaining the property during their stay.
          </li>
          <li>
            <b>3. Platform:</b> The digital marketplace operated by GuestHome,
            which connects Hosts and Guests. It includes the website and any
            associated applications or services that facilitate the listing and
            Booking of properties.
          </li>
          <li>
            <b>4. Guest Accepted by Host:</b> A Guest whose booking request has
            been confirmed and approved by the Host, resulting in a binding
            agreement for the booking of the property.
          </li>
          <li>
            <b>5. Processing Fee:</b> A fee charged by the Platform for the
            administration and handling of the Booking transaction. This fee is
            deducted from the booking amount and is distinct from the service
            fee.
          </li>
          <li>
            <b>6. Service Fee:</b> A fee charged by the Platform for providing
            its services, including listing properties and facilitating
            bookings. This fee is also deducted from the booking amount and is
            separate from the processing fee.
          </li>
          <li>
            <b>7. Applicable Law:</b> Refers to the laws and regulations of
            Ethiopia that govern the agreements and operations of the GuestHome
            Platform, including but not limited to civil, commercial, and
            property laws.
          </li>
          <li>
            <b>8. Refund:</b> The return of money to the Host after a booking is
            canceled or modified, subject to the terms and conditions outlined
            herein, including applicable deductions for service and processing
            fees.
          </li>
          <li>
            <b>9. Conflict Resolution:</b> The process by which disputes or
            disagreements between Hosts and Guests will be addressed and
            resolved, typically involving negotiation or mediation efforts
            before resorting to legal action.
          </li>
          <li>
            <b>10. Termination of Guest:</b> The process by which a Guest's
            rights to use the Platform and/or stay at a property are revoked,
            either by the Guest's own choice or by the Host due to violations of
            these Terms and Conditions.
          </li>
          <li>
            <b>11. Termination of Host:</b> The process by which a Host’s
            ability to list properties and engage in transactions on the
            Platform is revoked, typically due to violations of these Terms and
            Conditions or failure to comply with applicable laws.
          </li>
          <li>
            <b>12. Platform:</b> The digital marketplace operated by GuestHome,
            which allows Hosts to list their properties and Guests to make
            bookings. This includes the website and any associated applications
            or services.
          </li>
          <li>
            <b>13. Types of Property:</b> Refers to the various categories of
            booking properties available on the GuestHome Platform, which may
            include such as Hotel and Resort, Pension, Warehouse, House, Private
            room, Shared space, Unit of Condominium, Apartment, Hotel room,
            Guest house, Hall and others.
          </li>
        </ol>
      </section>

      <section>
        <h2>Article 2: Privacy Policy</h2>
        <h3>1. Data Collection</h3>
        <ol type="2">
          <li>
            The GuestHome Platform collects personal data to facilitate
            connections between Hosts and Guests.
          </li>

          <h3>2. Data Protection</h3>
          <li>
            The Platform will not intentionally disclose personal data to harm
            users.
          </li>
          <h3>3. Data Accuracy</h3>
          <li>
            Users providing personal data are responsible for its accuracy and
            credibility.
          </li>
          <h3>4. Data Retention</h3>
          <li>
            The Platform retains collected personal data as required by its
            operational systems.
          </li>
          <h3>5. Types of Personal Data</h3>
          <li>
            Personal data collected includes name, address, telephone number,
            email, booking price, and property type.
          </li>
          <h3>6. User Responsibility</h3>
          <li>
            Users are responsible for providing accurate, complete, and timely
            information.
          </li>
          <h3>7. Data Usage</h3>
          <li>
            Personal data is used for information processing, customer
            identification, registration, and access to the Platform and its
            services.
          </li>
          <h3>8. Communication</h3>
          <li>
            The Platform may use personal data to send information, maintain
            operations, deliver services, and comply with applicable laws.
          </li>
          <h3>9. Public Availability</h3>
          <li>
            Once personal data is published on the Platform, it is considered
            publicly available.
          </li>
          <h3>10. Data Security</h3>
          <li>
            The Platform employs measures to secure personal data but does not
            guarantee the security of online communications.
          </li>
          <h3>11. Policy Amendments</h3>
          <li>The Platform may change its privacy policy as needed.</li>
        </ol>
      </section>

      <section>
        <h2>Article 3: Host's Rights and Responsibilities</h2>
        <ol type="1">
          <h3>Rights of the Host:</h3>
          <li>
            1. To list their property on the GuestHome Platform for booking
            services.
          </li>
          <li>2. To collect booking fees directly from Guests.</li>
        </ol>
      </section>

      <section>
        <h2>Responsibilities of the Host:</h2>
        <ol type="1">
          <li>
            1. Provide required personal data to engage with the Platform.
          </li>
          <li>
            2. Furnish accurate personal data, including name, address, mobile
            number, booking price, and property description.
          </li>
          <li>3. Prepare a booking agreement for the Guest’s signature.</li>
          <li>
            4. Deposit a minimum of 1,000 birr for service and processing fee
            deductions (4% service Fee and 3% processing fee per Guest per day).
          </li>
          <li>
            5. Replenish the minimum deposit before exhaustion to avoid access
            blockage.
          </li>
          <li>6. Rent out properties at competitive and attractive prices.</li>
          <li>
            7. Keep a copy of the Guest’s ID or other legal identification.
          </li>
          <li>8. Comply with all government tax obligations. </li>
          <li>
            9. Accept Guests once confirmed and provide courteous hospitality.
          </li>
          <li>10. Maintain cleanliness and safety in the booking space.</li>
          <li>
            11. Provide necessary amenities such as water, soap, towels, bed
            linens, and toiletries.
          </li>
          <li>12. Arrange check-in and check-out times with Guests.</li>
          <li>13. Inform Guests about community norms and legal standards.</li>
          <li>14. Cooperate with the local community for smooth operations.</li>
          <li>
            15. If a Host seeks a refund after accepting a Guest, they must
            deduct 17% from the deposit, In addition to service and processing
            fees (7% total), and will receive the refund in local Currency
            within 30 days post-approval.
          </li>
        </ol>
      </section>
      <section>
        <h2>Article 4: Guest's Rights and Responsibilities</h2>
        <h3>Rights of the Guest:</h3>
        <ol type="1">
          <li>
            1. To choose a preferred property and Host at an agreed price.
          </li>
          <li>2. To enter into a contract with the Host for accommodation.</li>
        </ol>
        <h3>Responsibilities of the Guest:</h3>
        <ol>
          <li>1. Pay the booking price as listed on the Platform. </li>
          <li>
            2. Agree on payment methods with the Host, including cash, mobile
            banking, and other forms.
          </li>
          <li>
            3. Book the property in a timely manner and indicate the duration of
            stay.
          </li>
          <li>
            4. Confirm acceptance of the Host’s offer and check-in accordingly.
          </li>
          <li>
            5. Maintain cleanliness and respect the property during their stay.
          </li>
          <li>
            6. Follow social norms, community values, and legal obligations.
          </li>
          <li> 7. Keep a friendly environment with the Host.</li>
          <li>8. Book again through the Platform if extending the stay.</li>
        </ol>
        <h2>Article 5: Host and Guest Relationship</h2>
        <ol>
          <li>
            1. Upon booking a property, a contractual agreement is established
            directly between the Host and the Guest. This agreement outlines the
            terms and conditions of the stay, including pricing,
            check-in/check-out times, and any other relevant details.
          </li>
          <li>
            2. The Host is responsible for ensuring that the property meets the
            agreed-upon standards, Including cleanliness, safety, and the
            accuracy of the property description.
          </li>
          <li>
            3. The Guest is responsible for adhering to the terms of the
            booking, including maintaining the property during their stay and
            respecting the Host's rules.
          </li>
          <li>
            4. The GuestHome Platform is not responsible for any disputes,
            damages, or issues arising from the relationship between the Host
            and Guest. Any claims or concerns must be resolved directly between
            the parties involved.
          </li>
          <li>
            5. Both the Host and Guest agree to indemnify and hold harmless the
            GuestHome Platform from any claims, liabilities, damages, or
            expenses incurred as a result of their relationship or the use of
            the platform.
          </li>
        </ol>
      </section>
      <section>
        <h2>Article 6: Amendments to Terms</h2>
        <ol>
          <li>
            1. The Platform reserves the right to amend these Terms and
            Conditions as necessary, with Updates made available to users.
          </li>
        </ol>
      </section>
      <section>
        <h2>Article 7: General Provisions</h2>
        <ol type="2">
          <h3>1. Governing Law</h3>
          <li>
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of Ethiopia.
          </li>
          <h3>2. Severability</h3>
          <li>
            If any provision of these Terms and Conditions is found to be
            unenforceable or invalid, the remaining provisions shall continue in
            full force and effect.
          </li>
          <h3>3. No Waiver</h3>
          <li>
            The failure of the Platform to enforce any rights or provisions of
            these Terms and Conditions shall not be deemed a waiver of such
            rights or provisions.
          </li>
          <h3>4. Entire Agreement</h3>
          <li>
            These Terms and Conditions constitute the entire agreement between
            the users and the GuestHome Platform regarding the subject matter
            herein and supersede any prior agreements.
          </li>
          <h3>5. Contact Information</h3>
          <li>
            Users may contact the GuestHome Platform for any questions or
            concerns regarding these Terms and Conditions through the contact
            information provided on the Platform
          </li>
        </ol>
      </section>
    </div>
  );
}

export default Policy;
