import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const location = useLocation();
  const { t } = useTranslation();

  // Set initial active state based on current route
  const [active, setActive] = useState(location.pathname);

  // Update active state when location changes
  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  const handleNavClick = (navItem) => {
    setActive(navItem);
  };

  return (
    <div className="bottom-navigation">
      <nav className="nav-bar">
        <Link
          to="/"
          className={`nav-item ${active === "/" ? "active" : ""}`}
          onClick={() => handleNavClick("/")}
        >
          <HomeOutlinedIcon />
          <span>{t("Home")}</span>
        </Link>
        <Link
          to="/bookmark"
          className={`nav-item ${active === "/bookmark" ? "active" : ""}`}
          onClick={() => handleNavClick("/bookmark")}
        >
          <BookmarkBorderOutlinedIcon />
          <span>{t("Booked")}</span>
        </Link>
        <Link
          to="/setting"
          className={`nav-item ${active === "/setting" ? "active" : ""}`}
          onClick={() => handleNavClick("/setting")}
        >
          <SettingsOutlinedIcon />
          <span>{t("Setting")}</span>
        </Link>
        <Link
          to="/policy"
          className={`nav-item ${active === "/policy" ? "active" : ""}`}
          onClick={() => handleNavClick("/policy")}
        >
          <InfoOutlinedIcon />
          <span>{t("About us")}</span>
        </Link>
      </nav>
    </div>
  );
};

export default Footer;
