import React from "react";

const EmbeddedMap = ({ latitude, longitude, title }) => {
  // const mapUrl = `https://www.google.com/maps/embed/v1/view?zoom=14&center=${latitude},${longitude}&key=YOUR_API_KEY`;

  return (
    <div>
      <iframe
        style={{
          border: 0,
          width: "100%",
          height: "40%",
          borderRadius: "10px",
        }}
        loading="lazy"
        title={title}
        allowFullScreen
        src={`https://www.google.com/maps?q=${latitude},${longitude}&output=embed`}
      />
    </div>
  );
};

export default EmbeddedMap;