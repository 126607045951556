import React, { useState, useEffect } from "react";
import "./Header.css";

function Header() {
  const [currentImage, setCurrentImage] = useState(0);

  // List of images to swipe through
  const images = [
    "/1.png",
    "/2.png",
    "/3.png",
  ];

  useEffect(() => {
    // Change image every 5 seconds (5000ms)
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [images.length]);

  return (
    <div>
      <header style={{ backgroundImage: `url(${images[currentImage]})` }}>
        {/* <h3>
          Discover the ultimate solution for all your property needs with our
          app.
        </h3> */}
      </header>
    </div>
  );
}

export default Header;
