// TrendingCard
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

function TrendingCard({ property }) {

   const { t } = useTranslation();

  return (
    <div className="bg-white rounded-lg w-[300px] shadow-[0_4px_10px_rgba(0,0,0,0.11)] shadow-md m-2 flex-none">
      <Link to={`/detail/${property.id}`} className="block">
        <Swiper
          spaceBetween={4}
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="h-[140px] w-full object-cover rounded-t-lg"
        >
          {property.images.map((img, index) => (
            <SwiperSlide key={index}>
              <img
                src={img}
                alt={property.name}
                className="h-[140px] w-full object-cover rounded-t-lg"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="p-4 py-1">
          <div className="flex justify-between items-center">
            <p className="text-sm font-bold truncate">{property.name}</p>
            <div className="flex items-center">
              <i className="fa fa-star" style={{ color: "#FFA500" }}></i>
              <span className="text-xs text-gray-500">4.0/5.0</span>
            </div>
          </div>

          <p className="text-xs text-gray-600">{property.amenities}</p>
          <p className="text-xs text-gray-500 mt-1">
            {t("Posted by")} <b> {property.postedBy}</b>
          </p>
          <p className="text-xs mt-2 text-gray-800">
            <b>{property.price}</b>
          </p>
          <p className="text-xs text-gray-500">{property.location}</p>
        </div>
      </Link>
    </div>
  );
}

export default TrendingCard;
